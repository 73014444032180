<template>
  <section class="section container">
    <!-- <div class="spacer s30"></div> -->
    <!-- <h1 class="title has-text-centered">It looks like you're a little lost.. </h1> -->
    <article class="notification is-primary">
      <h1>Oops. Something went wrong.</h1>
    </article>

    <div class="centered">

      <h2>Try one of the links below:</h2>

      <div class="buttons centered">        
        <router-link to="/" class="button is-medium is-info"><font-awesome-icon icon="home" /> &nbsp;View Home Page</router-link>
      </div>
      
      <div class="buttons centered"> 
        <router-link to="feedback" class="button is-medium is-warning"><font-awesome-icon icon="phone" /> &nbsp;Get in touch with us</router-link>
      </div>
      
      <div class="buttons centered"> 
        <router-link to="pricing" class="button is-medium is-skip"><font-awesome-icon icon="money-bill-alt" /> &nbsp;View our Pricing</router-link>
      </div>
      
      <div class="buttons centered"> 
        <router-link to="faqs" class="button is-medium is-primary"><font-awesome-icon icon="info-circle" /> &nbsp;Read our FAQs</router-link>
      </div>
      
    </div>


  </section>
</template>

<script>

	import { store } from '../store/store';

	export default {
		

	}


</script>

<style lang="scss">

  article {
    h1 {
      text-align: center;
      margin-top: 10px;
    }
  }

  h2 {
    margin: 2rem auto;
    // font-weight: bold;
    font-size: 1.2rem;
  }


</style>
